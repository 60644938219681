.load-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 300px; */
    padding: 10px;
    background-color: white;
}

.box {
    flex: 3;
    background-color: grey;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
}

.queue {
    background-color: grey;
    flex: 1;
    height: 250px;
    margin-left: 10px;
    margin-right: 10px;
    overflow-y: scroll;
    padding: 10px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
}

.queue::-webkit-scrollbar {
    background-color: transparent; /* Make the track transparent */
}

.queue::-webkit-scrollbar-thumb {
    background-color: white; /* Make the track transparent */
    border-radius: 10px;
}

.loaded-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex: 1;
    height: 250px;
    margin-left: 10px;
    margin-right: 10px;
    overflow-y: scroll;
    margin-top: 20px;
}

.loaded-box::-webkit-scrollbar {
    background-color: transparent; /* Make the track transparent */
}

.loaded-box::-webkit-scrollbar-thumb {
    background-color: white; /* Make the track transparent */
    border-radius: 10px;
}

.choose-files-button {
    border-width: 0;
    background-color: white;
    margin: 3px;
    border: none;
}
