.item-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.item-box {
  height: 100%;
  width: 100%;
  background-color: white;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-content: center; */
  align-items: center;
  /* padding: 30px; */
}

.vid-name {
  width: 12rem;
  height: 2rem;
  /* overflow: scroll; */
  align-items: center;
  justify-content: center;
}

.vid-span{
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
}

.audio-span {
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
}


audio {
  height: 2rem;
  width: 100%;
}

.delete {
  width: 100%;
}

.delete-button {
  background-color: red;
  color: white;
  border-color: transparent;
  width: 100%;
}

.transition {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  height: auto;
  background-color: transparent;
}

.index {
  background-color: black;
  display: flex;
  color: white;
  width: 100%;
  height: 30px;
  align-items: center;
  justify-content: center;
  /* padding: 5px; */
}

.add {
  margin: 10px;
  background-color: green;
  color: white;
  font-size: 40px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-width: 0;
  /* padding-bottom: 5px; */
}

.dropdown {
  width: 100px;
  margin-top: 20px;
}

h2 {
  text-align: center;
}

.sound-text {
  font-size: x-small;
  max-width: 200px;
  /* word-wrap: break-word; */
  overflow: scroll;
}
